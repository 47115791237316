import dynamic from 'next/dynamic';

const PostcodeListPreview = dynamic(
  () => import('@foxtons/fdl/layouts/PostcodeListPreview/index')
);
const FindAccommodationBox = dynamic(
  () => import('@foxtons/fdl/layouts/FindAccommodationBox/index')
);

const PostcodePreview = dynamic(() => import('@layouts/PostcodePreview/index'));
const HorizontalSectionFullImage = dynamic(
  () => import('@foxtons/fdl/layouts/GetAhead/index')
);
const Conveyance = dynamic(
  () => import('@foxtons/fdl/layouts/ConveyanceSection/index')
);
const AlexanderHall = dynamic(
  () => import('@foxtons/fdl/layouts/AlexanderHallSection/index')
);
const GetAhead = dynamic(
  () => import('@foxtons/fdl/layouts/GetAheadSection/index')
);
const HorizontalSectionClippedImage = dynamic(
  () => import('@foxtons/fdl/layouts/HorizontalSectionClippedImage/index')
);
const LogosBox = dynamic(() => import('@foxtons/fdl/layouts/LogosBox/index'));
const ExploreFoxtons = dynamic(
  () => import('@foxtons/fdl/layouts/ExploreFoxtons/index')
);
const HelpBox = dynamic(() => import('@foxtons/fdl/layouts/HelpBox/index'));

const LearnMore = dynamic(() => import('@foxtons/fdl/layouts/LearnMore/index'));
const HeroBanner = dynamic(
  () => import('@foxtons/fdl/layouts/HeroBanner/index')
);
const InstructForm = dynamic(() => import('@layouts/InstructForm'));
const HeroBannerCarousel = dynamic(
  () => import('@foxtons/fdl/layouts/HeroBannerCarousel/index')
);
const HeroSearchSection = dynamic(
  () => import('@components/HeroSearchSection/index')
);

const HorizontalScroll = dynamic(
  () => import('@foxtons/fdl/layouts/HorizontalScroll/index')
);
const UniversityList = dynamic(
  () => import('@foxtons/fdl/layouts/UniversityList/index')
);
const FAQs = dynamic(() => import('@foxtons/fdl/layouts/FAQs/index'));

const FullFeatureSection = dynamic(
  () => import('@foxtons/fdl/layouts/FullFeatureListSection/index')
);

const BlogsList = dynamic(() => import('@foxtons/fdl/layouts/BlogList/index'));

const UniversityMapLayout = dynamic(
  () => import('../../layouts/UniversityDetailSection/index')
);
const ServiceCardsSection = dynamic(
  () => import('@foxtons/fdl/layouts/ServiceCardsSection/index')
);

const Footer = dynamic(() => import('@foxtons/fdl/components/Footer/index'));

const FoxtonsLoader = dynamic(
  () => import('@foxtons/fdl/components/FoxtonsLoader/index')
);

const SimplifyingProcessSection = dynamic(
  () => import('@foxtons/fdl/layouts/SimplifyingProcessSection/index')
);

const TestimonialCarousel = dynamic(
  () => import('@foxtons/fdl/components/TestimonialCarousel/index')
);

const Testimonials = dynamic(
  () => import('@foxtons/fdl/components/TestimonialTextCarousel/index')
);

const PersonaCardsSection = dynamic(
  () => import('@foxtons/fdl/layouts/PersonaCardsSection/index')
);

const AreaCardsSection = dynamic(
  () => import('@foxtons/fdl/layouts/AreaCardsSection/index')
);
const FoxtonsPrimeTeamSection = dynamic(
  () => import('@foxtons/fdl/layouts/FoxtonsPrimeTeamSection/index')
);

const MarketingPropertyCardSection = dynamic(
  () => import('@foxtons/fdl/layouts/MarketingPropertyCardSection/index')
);

const MarketingPropertyGridSection = dynamic(
  () => import('@foxtons/fdl/layouts/MarketingPropertyGridSection/index')
);

const TravelTimeSection = dynamic(
  () => import('@components/TravelTimeSection/index')
);

const MarketingPropertyCardDrawerSection = dynamic(
  () => import('@foxtons/fdl/layouts/MarketingPropertyCardDrawerSection/index')
);

const ManagementInfoCardSection = dynamic(
  () => import('@foxtons/fdl/layouts/ManagementInfoCardSection/index')
);

const HelpBoxImage = dynamic(
  () => import('@foxtons/fdl/layouts/HelpBoxImage/index')
);

const InformationSection = dynamic(
  () => import('@foxtons/fdl/layouts/InformationSection/index')
);

const FoxtonsHelpSection = dynamic(
  () => import('@foxtons/fdl/layouts/FoxtonsHelpSection/index')
);

const MortgageCardsSection = dynamic(
  () => import('@foxtons/fdl/layouts/MortgageCardsSection/index')
);

const WhatsIncludedSection = dynamic(
  () => import('@foxtons/fdl/components/Table/index')
);

const BorrowCalculatorSection = dynamic(
  () => import('@layouts/BorrowCalculatorSection/index')
);

const StampDutyCalculator = dynamic(
  () => import('@layouts/StampDutyCalculatorSection/index')
);

const YieldCalculator = dynamic(
  () => import('@layouts/RentalYieldCalculator/index')
);

const GeneralContactForm = dynamic(
  () => import('@layouts/GeneralContactForm/index')
);

const MapWidgetComponent = dynamic(
  () => import('@components/MapWidgetComponent/index')
);
const History = dynamic(
  () => import('@foxtons/fdl/layouts/FoxtonsHistory/index')
);

const DiscoverLocalLife = dynamic(
  () => import('@components/DiscoverLocalLife/index')
);

const LocalLifeAreasList = dynamic(
  () => import('@components/LocalLifeAreasList/index')
);
const TestimonialsBlock = dynamic(
  () => import('@foxtons/fdl/layouts/ReviewTestimonials/index')
);

const ReviewTestimonialsStrapi = dynamic(
  () => import('@foxtons/fdl/layouts/ReviewTestimonialsStrapi/index')
);

const CookiePolicyDetails = dynamic(
  () => import('@foxtons/fdl/layouts/CookiesPolicySection/index')
);

const CookiePreferencesModal = dynamic(
  () => import('@foxtons/fdl/components/CookieModal/CookiePreferencesModal'),
  { ssr: false }
);

const RegulatoryBodyDetails = dynamic(
  () => import('@foxtons/fdl/layouts/RegulatoryBodiesDetailSection/index')
);

const FaqCareers = dynamic(
  () => import('@foxtons/fdl/layouts/FAQCareer/index')
);

const StaffCards = dynamic(
  () => import('@foxtons/fdl/layouts/StaffCareerSection/index')
);

const HeroBannerLanguage = dynamic(
  () => import('@foxtons/fdl/layouts/HeroBannerLanguage/index')
);

const TalkToLocalExpert = dynamic(
  () => import('@layouts/TalkToLocalExpert/index'),
  {
    ssr: false
  }
);

const SearchPropertiesBox = dynamic(
  () => import('@layouts/SearchPropertiesBox/index')
);

const InformationDrawer = dynamic(
  () => import('@components/InformationDrawer/index')
);

const ChineseContactForm = dynamic(
  () => import('@components/ChineseContactForm/index')
);

const LettingsContactBox = dynamic(
  () => import('@components/LettingsContactBox/index')
);

const ReportSection = dynamic(
  () => import('@foxtons/fdl/layouts/ReportSection/index')
);

const ReportHeader = dynamic(
  () => import('@foxtons/fdl/layouts/ReportHeader/index')
);

const PropertyInfoSection = dynamic(
  () => import('@foxtons/fdl/layouts/PropertyInfoSection/index')
);

const ComplaintProcessSection = dynamic(
  () => import('@foxtons/fdl/layouts/ComplaintProcessSection/index')
);

const ContactRecruitment = dynamic(
  () => import('@foxtons/fdl/layouts/ContactFoxtonsRecuritment/index')
);

const FoxtonsMiniSection = dynamic(
  () => import('@foxtons/fdl/layouts/FoxtonsMiniSection/index')
);

const InfoBanner = dynamic(
  () => import('@foxtons/fdl/layouts/InfoBanner/index')
);

const SchoolPerformanceTable = dynamic(
  () => import('@foxtons/fdl/layouts/SchoolPerformanceTable/index')
);

const CultureCardSection = dynamic(
  () => import('@foxtons/fdl/layouts/CultureCardSection/index')
);

const NearestOfficeSection = dynamic(
  () => import('@foxtons/fdl/layouts/NearestOfficeSection/index')
);

const OfficesListSection = dynamic(
  () => import('@foxtons/fdl/layouts/OfficesListSection/index')
);

const ExploreOfficeAddress = dynamic(
  () => import('@foxtons/fdl/layouts/ExploreOfficeAddress/index')
);

const EnquiriesSideDrawer = dynamic(
  () => import('@components/EnquiriesSideDrawer/index')
);

const FeeInfoSection = dynamic(
  () => import('@foxtons/fdl/layouts/FeeInfoSection/index')
);

const FeesSideDrawer = dynamic(
  () => import('@foxtons/fdl/layouts/FeesSideDrawer/index')
);

const FeeInfoAccordionSection = dynamic(
  () => import('@foxtons/fdl/layouts/FeeInfoAccordionSection/index')
);

const RentalProtectionTCs = dynamic(
  () => import('@foxtons/fdl/layouts/RentalProtectionTCs/index')
);

const AvailableAssetSection = dynamic(
  () => import('@foxtons/fdl/layouts/AvailableAssestSection/index')
);

const PrivacyPolicyAndTCs = dynamic(
  () => import('@foxtons/fdl/layouts/PrivacyPolicyAndTCs/index')
);

const MLRegulationsSection = dynamic(
  () => import('@foxtons/fdl/layouts/MLRegulationsSection/index')
);

const LocalCouncilSection = dynamic(
  () => import('@foxtons/fdl/layouts/LocalCouncilSection/index')
);

const FAQsTabs = dynamic(() => import('@foxtons/fdl/layouts/FAQsTabs/index'));

const PropertyInvestmentModel = dynamic(
  () => import('@foxtons/fdl/layouts/PropertyInvestmentModel/index')
);

const HousePricesArea = dynamic(() => import('@layouts/HousePricesArea/index'));
const SelectAreaAccordion = dynamic(
  () => import('@components/SelectAreaAccordion')
);
const HousePricePageTypes = dynamic(
  () => import('@components/HousePricePageTypes')
);

const InteriorDesignCategories = dynamic(
  () => import('@foxtons/fdl/layouts/InteriorDesignCategories/index')
);

const InvestorsCaseStudies = dynamic(
  () => import('@foxtons/fdl/layouts/InvestorsCaseStudies/index')
);

const LandlordCompliance = dynamic(
  () => import('@foxtons/fdl/layouts/LandlordCompliance/index')
);

const JobOpportunityCards = dynamic(
  () => import('@components/Careers/JobOpportunityCards')
);

const SocialMobilitySection = dynamic(
  () => import('@foxtons/fdl/layouts/SocialMobilitySection/index')
);

const SponseringLocalSchools = dynamic(
  () => import('@foxtons/fdl/layouts/SponseringLocalSchools/index')
);

const FoxtonsAwardsList = dynamic(
  () => import('@foxtons/fdl/layouts/FoxtonsAwardsList/index')
);

const ValuationPostcodeSection = dynamic(
  () => import('@foxtons/fdl/layouts/ValuationPostcodeSection/index')
);

const ValuationForm = dynamic(() => import('@foxtons/fdl/valuation/index'));

const BluWaterVideoSection = dynamic(
  () => import('@foxtons/fdl/layouts/BluWaterVideoSection/index')
);

const BlogCarousel = dynamic(() => import('@components/BlogCarousel/index'));

const LibImageWallSection = dynamic(
  () => import('@foxtons/fdl/layouts/ImageWallSection/index')
);

const TextCardCarousel = dynamic(
  () => import('@foxtons/fdl/layouts/TextCardCarousel/index')
);

const AccordionWithTitle = dynamic(
  () => import('@foxtons/fdl/layouts/AccordionWithTitle/index')
);

const IconTextSection = dynamic(
  () => import('@foxtons/fdl/layouts/IconTextSection/index')
);

const LondonsNumberOneHero = dynamic(
  () => import('@foxtons/fdl/layouts/LondonsNumberOneHero/index')
);

const RecommendedPropertiesSection = dynamic(
  () => import('@layouts/RecommendedPropertiesSection/index')
);

const PropertySearchCards = dynamic(
  () => import('@layouts/PropertySearchCards/index')
);

const MortgageAdviceFormDrawer = dynamic(
  () => import('@components/MortgageAdvice/MortgageAdviceFormDrawer')
);

const ComparisonTable = dynamic(
  () => import('@foxtons/fdl/layouts/ComparisonTable/index')
);

const SeasonalPromotionsBanner = dynamic(
  () => import('@foxtons/fdl/layouts/SeasonalPromotionsBanner/index')
);

const TrustPilotTestimonialRibbon = dynamic(
  () => import('@components/TrustPilotTestimonialRibbon/index')
);

const getSections = (name: string) => {
  switch (name) {
    case 'SimplifyingProcessSection':
      return SimplifyingProcessSection;
    case 'HorizontalSectionFullImage':
      return HorizontalSectionFullImage;
    case 'Conveyance':
      return Conveyance;
    case 'AlexanderHall':
      return AlexanderHall;
    case 'GetAhead':
      return GetAhead;
    case 'HorizontalSectionClippedImage':
      return HorizontalSectionClippedImage;
    case 'HelpBoxImage':
      return HelpBoxImage;
    case 'InformationSection':
      return InformationSection;
    case 'LogosBox':
      return LogosBox;
    case 'ExploreFoxtons':
      return ExploreFoxtons;
    case 'HelpBox':
      return HelpBox;
    case 'LearnMore':
      return LearnMore;
    case 'HeroBanner':
      return HeroBanner;
    case 'HeroBannerCarousel':
      return HeroBannerCarousel;
    case 'HeroSearchSection':
      return HeroSearchSection;
    case 'HorizontalScroll':
      return HorizontalScroll;
    case 'UniversityList':
      return UniversityList;
    case 'FAQs':
      return FAQs;
    case 'FullFeatureSection':
      return FullFeatureSection;
    case 'BlogsList':
      return BlogsList;
    case 'UniversityMapLayout':
      return UniversityMapLayout;
    case 'ServiceCardsSection':
      return ServiceCardsSection;
    case 'PersonaCardsSection':
      return PersonaCardsSection;
    case 'AreaCardsSection':
      return AreaCardsSection;
    case 'FoxtonsTeamSection':
      return FoxtonsPrimeTeamSection;
    case 'ManagementInfoCardSection':
      return ManagementInfoCardSection;
    case 'InstructForm':
      return InstructForm;
    case 'Footer':
      return Footer;
    case 'FoxtonsLoader':
      return FoxtonsLoader;
    case 'TestimonialCarousel':
      return TestimonialCarousel;
    case 'Testimonials':
      return Testimonials;
    case 'FoxtonsHelpSection':
      return FoxtonsHelpSection;
    case 'MortgageCardsSection':
      return MortgageCardsSection;
    case 'WhatsIncludedSection':
      return WhatsIncludedSection;
    case 'BorrowCalculator':
      return BorrowCalculatorSection;
    case 'StampDutyCalculator':
      return StampDutyCalculator;
    case 'YieldCalculator':
      return YieldCalculator;
    case 'GeneralContactForm':
      return GeneralContactForm;
    case 'MapWidgetComponent':
      return MapWidgetComponent;
    case 'DiscoverLocalLife':
      return DiscoverLocalLife;
    case 'LocalLifeAreasList':
      return LocalLifeAreasList;
    case 'History':
      return History;
    case 'TestimonialsBlock':
      return TestimonialsBlock;
    case 'TestimonialBlockWithContent':
      return ReviewTestimonialsStrapi;
    case 'CookiePolicyDetails':
      return CookiePolicyDetails;
    case 'CookiePreferencesModal':
      return CookiePreferencesModal;
    case 'TalkToLocalExpert':
      return TalkToLocalExpert;
    case 'RegulatoryBodyDetails':
      return RegulatoryBodyDetails;
    case 'FaqCareers':
      return FaqCareers;
    case 'StaffCards':
      return StaffCards;
    case 'LanguageHeroBanner':
      return HeroBannerLanguage;
    case 'MarketingPropertyListCarousel':
      return MarketingPropertyCardSection;
    case 'MarketingPropertyGridSection':
      return MarketingPropertyGridSection;
    case 'TravelTimeSection':
      return TravelTimeSection;
    case 'PropertyMarketingCardsSection':
      return MarketingPropertyCardDrawerSection;
    case 'SearchPropertiesBox':
      return SearchPropertiesBox;
    case 'InformationDrawer':
      return InformationDrawer;
    case 'ChineseContactForm':
      return ChineseContactForm;
    case 'Reports':
      return ReportSection;
    case 'ReportsPageTitle':
      return ReportHeader;
    case 'LettingsContactBox':
      return LettingsContactBox;
    case 'RichTextSection':
      return PropertyInfoSection;
    case 'ComplaintProcess':
      return ComplaintProcessSection;
    case 'OfficeHours':
      return NearestOfficeSection;
    case 'FoxtonsOffices':
      return OfficesListSection;
    case 'ExploreOfficeAddress':
      return ExploreOfficeAddress;
    case 'EnquiriesSideDrawer':
      return EnquiriesSideDrawer;
    case 'ContactRecruitment':
      return ContactRecruitment;
    case 'FoxtonsMinis':
      return FoxtonsMiniSection;
    case 'FeeSection':
      return FeeInfoSection;
    case 'FeesSideDrawer':
      return FeesSideDrawer;
    case 'FoxtonsFee':
      return FeeInfoAccordionSection;
    case 'InfoBanner':
      return InfoBanner;
    case 'SchoolsTable':
      return SchoolPerformanceTable;
    case 'PictureGallery':
      return CultureCardSection;
    case 'TermsAndConditions':
      return RentalProtectionTCs;
    case 'AssetLibrary':
      return AvailableAssetSection;
    case 'TermsAndConditionsDetails':
      return PrivacyPolicyAndTCs;
    case 'IdentityDocumentsDetails':
      return MLRegulationsSection;
    case 'AccordionWithTabs':
      return FAQsTabs;
    case 'LocalCouncil':
      return LocalCouncilSection;
    case 'SliderWithIcons':
      return PropertyInvestmentModel;
    case 'SelectAreaMapItem':
      return HousePricesArea;
    case 'SelectAreaAccordion':
      return SelectAreaAccordion;
    case 'HousePricePageTypes':
      return HousePricePageTypes;
    case 'FindAccommodationBox':
      return FindAccommodationBox;
    case 'PostcodePreview':
      return PostcodePreview;
    case 'PostcodeListPreview':
      return PostcodeListPreview;
    case 'InteriorDesignCategories':
      return InteriorDesignCategories;
    case 'CardsStack':
      return InvestorsCaseStudies;
    case 'CardsStackWithTab':
      return LandlordCompliance;
    case 'JobOpportunityCards':
      return JobOpportunityCards;
    case 'BannerImageWithDescription':
      return SocialMobilitySection;
    case 'ImageCardsSection':
      return SponseringLocalSchools;
    case 'FoxtonsAwards':
      return FoxtonsAwardsList;
    case 'ValuationPostcodeSection':
      return ValuationPostcodeSection;
    case 'ValuationForm':
      return ValuationForm;
    case 'VideoComponent':
      return BluWaterVideoSection;
    case 'BlogCarousel':
      return BlogCarousel;
    case 'ImageWallSection':
      return LibImageWallSection;
    case 'TextCardCarousel':
      return TextCardCarousel;
    case 'AccordionWithTitle':
      return AccordionWithTitle;
    case 'IconTextSection':
      return IconTextSection;
    case 'LondonsNumberOneHero':
      return LondonsNumberOneHero;
    case 'RecommendedPropertiesSection':
      return RecommendedPropertiesSection;
    case 'PropertySearchCards': // for LettingsSearchCards and SalesSearchCards
      return PropertySearchCards;
    case 'MortgageAdviceFormDrawer':
      return MortgageAdviceFormDrawer;
    case 'ComparisonTable':
      return ComparisonTable;
    case 'SeasonalPromotionsBanner':
      return SeasonalPromotionsBanner;
    case 'TrustPilotTestimonialRibbon':
      return TrustPilotTestimonialRibbon;

    default:
      return function NotComponent() {
        return null;
      };
  }
};

export default getSections;

import Head from 'next/head';
import { useRouter } from 'next/router';

export interface MetaTagsProps {
  title: string;
  description?: string;
  keywords?: string;
  canonical?: string;
  image?: string;
  url?: string;
  twitterCreator?: string;
  twitterSite?: string;
  twitterSummary?: string;
  ogType?: string;
  ogSiteName?: string;
  fbAdmins?: string;
  noIndexFollow?: boolean;
}

function MetaTags({
  description = '',
  image = '',
  keywords = '',
  title,
  url = '',
  twitterCreator = '',
  twitterSite = '',
  twitterSummary = '',
  ogType = '',
  ogSiteName = '',
  fbAdmins = '',
  noIndexFollow = false,
  canonical: canonicalUrl = ''
}: MetaTagsProps) {
  const re = /^(http|https):\/\//i;
  const baseUrlRaw = (url || process.env.NEXT_PUBLIC_URL).replace(re, '');
  const baseUrl = baseUrlRaw.slice(
    0,
    baseUrlRaw.indexOf('/') > -1 ? baseUrlRaw.indexOf('/') : baseUrlRaw.length
  );
  const router = useRouter();

  const canonical =
    canonicalUrl ||
    `${process.env.NEXT_PUBLIC_URL}${router.asPath}`.split('?')[0];

  return (
    <Head>
      <title>{title}</title>
      <link rel="icon" href="/favicon.ico" />
      {description && <meta name="description" content={description} />}
      <meta name="keywords" content={keywords} />
      <link rel="canonical" href={canonical} />

      {/* open graph */}
      <meta property="og:url" content={canonical} />
      {ogType ? <meta property="og:type" content={ogType} /> : null}
      <meta property="og:title" content={title} name={title} />
      {description && <meta property="og:description" content={description} />}
      {image ? <meta property="og:image" content={image} /> : null}
      {ogSiteName ? (
        <meta property="og:site_name" content={ogSiteName} />
      ) : null}
      {fbAdmins ? <meta property="fb:admins" content={fbAdmins} /> : null}

      {/* twitter */}
      {twitterSummary ? (
        <meta name="twitter:card" content={twitterSummary} />
      ) : null}
      <meta property="twitter:domain" content={baseUrl} />
      <meta property="twitter:url" content={canonical} />
      {twitterSite ? <meta name="twitter:site" content={twitterSite} /> : null}
      <meta name="twitter:title" content={title} />
      {description && <meta name="twitter:description" content={description} />}
      {twitterCreator ? (
        <meta name="twitter:creator" content={twitterCreator} />
      ) : null}
      {image ? <meta name="twitter:image" content={image} /> : null}
      {noIndexFollow ? <meta name="robots" content="noindex, follow" /> : null}
    </Head>
  );
}

export default MetaTags;
